@import '../variables';

$social-link-icon-color: blue !default;

.social-links-block {

  .social-links {
    display: flex;
    list-style: none;
    margin: 0 0 18px 0;
    padding-left: 0;
    justify-content: center;

    @media (min-width: #{$mobile-bp}) {
      margin: $md-pad 0;
    }

    .social-link {
      padding: 8px;
      display: flex;
      align-items: center;

      a:hover {
        svg {
          fill: $light-blue;
          transition: $base-transition;
        }
      }

      svg {
        width: 20px;
        height: 20px;
        fill: white;
        transition: $base-transition;
      }
    }
  }
}