@import 'variables';
@import 'partials/mixins';



.home main {
  padding: 0 0 0 0;

  .content-container {
    height: 100%;
  }

  .content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;

    // Late Edits
    padding-left: 8%;
    padding-right: 8%;
    max-width: 1024px;

    @media (max-width: #{$tablet-bp}) {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .footer .content {
    max-width: 100%;
  }

  .content .inner {
    padding-bottom: 4rem;
    width: 100%;
  }

  .full-content {
    max-width: 100vw;
    width: 100vw;
  }

}

#fullpage .section {
  overflow: hidden;

  .inner {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    padding-top: calc(130px + 4rem);
  }

  .logo {
    width: 100px;
    top: 16px;
    left: 0;
    position: absolute;

    @media (min-width: #{$mobile-bp}) {
      width: 130px;
      top: 32px;
    }
  }

}


#fullpage #section-1 {
}

#fullpage #section-4 {

  .text-block {
    max-width: 750px;
  }

  #ofo-sb-circle {
    top: 40%;
    right: -70%;
    position: absolute;
    z-index: 1;

    @media (max-width: 1100px) {
      display: none;
    }
  }
}

#fullpage #section-5 {
  #ofo-sb-circle {
    top: -60%;
    right: -70%;
    z-index: 0;
    #Content {
      fill: white;
      opacity: 0.3;
    }
  }

  .content .inner {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .ticker {
    position: relative;

    @media (max-width: $mobile-bp) {
      font-size: 28px;
    }

    .big-font {
    }
  }

  .ticker-1 {
    align-self: flex-end;

    .big-font {
    }
  }

  .ticker-2 {
    .big-font {
      position: absolute;
      padding-left: 22px;
    }
  }



}

// Animations
#logo-svg {
  transform: scale(0.8);
  @include transition( $base-transition );
}

.fp-viewing-slide1 {
  #logo-svg {
    transform: scale(1);
    @include transition( $base-transition );
  }
}

.js .el-fade-in {
  opacity: 0;
}

#ofo-sb-circle {
  width: 100vw;
  position: absolute;
  z-index: -1;
}

.fancy-box {
  border: 1px solid $brand-blue;
  padding: 2rem;
  position: relative;
  margin: 2rem 0;

  &:before {
    content: ' ';
    position: absolute;
    z-index: -1;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 1px solid $brand-blue;
    background-color: $light-blue;
  }

  h2, ul {
    color: white;
    margin: 0 0 1rem 0;
  }

  ul {
    margin-bottom: 0;
  }


}

.client-login-block {
  display: none;
  //background-color: rgba(25, 63, 110, 0.65);
  position: absolute;
  right: 0;
  margin: 0 auto;
  padding-right: 15px;
  z-index: 4000;

  .content {
    text-align: right;
  }

  .login-link {
    color: white;
    opacity: 1;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    @include transition( $base-transition );

    &:hover {
      opacity: 0.7;
      text-decoration: none;
      @include transition( $base-transition );
    }
  }
}

.fp-viewing-slide1 .client-login-block {
  display: inherit;
}

#fullpage .footer .content-container .content {
  padding-top: 0;
  padding-bottom: 0;

  .logo {
    position: absolute !important;
  }

  .content-left {
    padding-left: 30px;
    @media (max-width: 600px) {
      padding-left: 0;
    }
  }
}
