.article-hero {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .hero-content {
    margin: 0 auto;
    max-width: 1170px;
    height: 100%;
    display: flex;
    align-items: flex-end;

    .inner {
      margin-bottom: 30vh;
    }



    h1 {
      color: white;
      margin-bottom: 0;
    }

    h4 {
      color: white;
      margin-top: 0;
    }

    h1, h4 {
      @media (max-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    .profile-qualifications {
      font-weight: normal;
      font-size: 18px;
      margin-top: 12px;
    }
  }
}