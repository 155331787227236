@import 'variables';

.contact-page {
  main {
    padding: 0 0 0 0;
  }

  .content-container {
    @extend .base-content-container;
  }

  h1, p {
    color: white;
  }

  .modal-title {
    @extend .base-h1;
    color: white;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0;
  }

  .content-two-columns a {
    @extend .link-color-inverse;
    @extend .white-hov;

  }


}

.contact-meta-block {
  min-width: 260px;
  margin-left: 2rem;

  ul li a {
    color: white !important;
  }
}

.icon {
  width: 22px;
  height: 22px;
  margin-top: 8px;
  margin-right: 10px;
  align-self: flex-start;
}

.icon, .value {
  min-height: 10px;
}

.contact-meta-block {
  ul {
    list-style: none;
    margin-top: 0;
    padding-left: 0;
  }

  li a {
    display: flex;
    margin-bottom: 1rem;
    color: white !important;
    font-weight: 200;
    @include transition( all .3s ease-in-out );

    &:hover {
      color: $light-blue !important;
      @include transition( $base-slide-transition );
    }
  }
}

.map-embed {
  margin: 2rem 0;
}

.contact-content-bottom {
  margin: 0 auto;
}

.contact-page .content-container .content {
  padding-top: calc(130px + 4rem);
}

.content-two-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    justify-content: center;
    flex-wrap: wrap;

    .column-left {
      margin-bottom: 2rem;
    }
  }


  .content-left {
    margin-right: 2rem;
    //max-width: 550px;

    @media (max-width: 600px) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }

  .content-right {
    max-width: 275px;
    @media (max-width: 600px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .contact-meta-block {
        margin-left: 0;
      }
    }
  }
}

.contact-page .content-two-columns {
  align-items: inherit;

  .column-right {
    margin-top: 0;

    @media (min-width: 600px) {
      margin-top: 106px;
    }


    @media (min-width: $tablet-bp) {
      margin-top: 148px;
    }
  }
}

.contact-form-modal {
  display: none;
  position: fixed;
  z-index: 8000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);

  .contact-form-content {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 4rem;
    overflow-y: scroll;
    z-index: 9000;


    @media (max-width: 800px) {
      padding: 2rem;
    }

    @media (max-width: 450px) {
      padding: 1rem;
    }
    }

    .background-image-wrapper {
    position: fixed !important;
  }


  .content-two-columns {
    display: flex;

    @media (max-width: 800px) {
      justify-content: center;
    }

    .column {
      width: 50%;
      @media (max-width: 800px) {
        width: 100%;
      }
    }

    .left-column {
      margin-right: 2rem;

      @media (max-width: 800px) {
        margin-right: 0;
      }

    }
    .right-column {
      margin-top: 40px;
      display: flex;
      justify-content: center;

      @media (max-width: 800px) {
        display: none;
      }

      img {
        object-fit: cover;
        height: calc(100% - 1rem);
        max-height: 660px;
      }
    }
  }

  h1, p {
    color: $brand-blue;
    font-weight: bold;
  }
  input, textarea {
    margin-top: 0.25rem;
  }
}

.contact-page .contact-form-modal.active {
  position: fixed;
  display: inherit;
}


form {
  padding: 1rem;

  span.wpcf7-not-valid-tip {
    font-size: 14px;
    padding-top: 5px;
  }

  div.wpcf7-validation-errors {
    background-color: #f00;
    border-color: #f00;
  }
}

.contact-page form label {
  color: white;
  margin-bottom: 1rem;
  font-weight: 200;
  width: 100%;
}

.contact-page form input, .contact-page form textarea {
  border-radius: 0;
}

.contact-page form input[type=submit] {
  width: 100%;
  background-color: #51A0D1;
  @include transition( all .3s ease-in-out );

  &:hover {
    @include transition( all .3s ease-in-out );
    background-color: $brand-blue;
    border-color: $brand-blue;
  }
}

.wpcf7-mail-sent-ok, .wpcf7 form.sent .wpcf7-response-output  {
  background-color: #398f14;
  color: white;
}

.wpcf7 form.invalid .wpcf7-response-output{
  background-color: #ffb900;
  color: white;
}

.wpcf7 form.spam .wpcf7-response-output {
  background-color: #f56e28;
  color: white;
}