@import '~marx-css/scss/variables'; // Import marx variables;

$base-padding: $md-pad;
$base-border: 1px solid rgba(0, 0, 0, 0.12);
$base-background-color: $grey;

$base-transition: all .3s ease;
$base-slide-transition: all 1.4s ease;

$site-max-width: 1170px;

// BREAKPOINTS
$xs-bp: 420px;
$sm-bp: 600px;
$mobile-bp: 850px;
$tablet-bp: 775px;

// COLORS
$brand-blue: #193F6E;
$light-blue: #889cbb;

$brand-blue-overlay: rgba(25,63,110,0.65);
$color-1: $brand-blue;
$base-font-color: #383838;

$hamburger-color: $brand-blue;
$hamburger-color-active: white;

// FONTS
$sans-serif-font: aktiv-grotesk, sans-serif;
$serif-font: garamond-fb-display, serif;

$font-size-base: 20px;
$font-size-1: 48px;
$font-size-2: 48px;
$font-size-3: 48px;
$font-size-4: 36px;
$font-size-5: 24px;
$font-size-6: 18px;

$base-text-shadow: #000 0 2px 4px;
