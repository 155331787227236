@import '../variables';
@import 'partials/mixins';

@media (min-width: #{$mobile-bp}) {
  .banner-active #site-header,
  .banner-active #fullpage .section .logo {
    margin-top: 41px;
  }
}
@media (max-width: #{$mobile-bp}) {
  .mtsnb {
    display: none;
  }
}

#site-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 32px 8%;

  @media (max-width: #{$tablet-bp}) {
    padding: 16px;
  }

  .inner {
    margin: 0 auto;
  }

  #nav-main {
    display: none;
    font-size: 30px;
    font-family: $serif-font;

    @media (min-width: #{$mobile-bp}) {
      font-size: $font-size-2;
    }

    a {
      color: white;
    }

    ul {
      padding-left: 0;
    }
  }

  &.is-active {
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    background-size: cover;
    background-image: url('/wp-content/themes/plank/static/images/hamburger-image.jpg');

    #nav-main {
      display: flex;
      justify-content: center;

    }
  }
}

.site-header-top {
  position: relative;
  display: flex;
  justify-content: space-between;

  .site-header-top__inner {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }

  .logo {
    width: 100px;
    margin-top: 0;
    margin-bottom: 0;
    opacity: 0;
    @include transition( $base-transition );

    @media (min-width: #{$mobile-bp}) {
      width: 130px;
    }

    &.active {
      opacity: 1;
      @include transition( $base-transition );
    }
  }

  #logo-svg {
      animation: rotation 2s infinite linear;
  }

  #ofo-logo {
    fill: $brand-blue;
  }
  #logo-text-bg {
    fill: white;
  }
}

.home .site-header-top .logo {
  opacity: 0;
  @include transition( $base-transition );
}

.hamburger-active .site-header-top .logo {
  opacity: 1;
}

#hamburger {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;

  .hamburger-inner {
    background-color: $hamburger-color;
    &:after, &:before {
      background-color: $hamburger-color;
    }
  }
}

#site-header .logo {
  #ofo-logo, #logo-text-bg {
    @include transition( $base-transition );
  }

  #hamburger {
    .hamburger-inner {
      @include transition( $base-transition );
      &:after, &:before {
        @include transition( $base-transition );
      }
    }
  }
}

.hamburger-active, [data-bgColor="dark"], .bg-color-dark {
  #site-header {
    .logo {
      #ofo-logo {
        fill: white;
        @include transition( $base-transition );
      }
      #logo-text-bg {
        fill: $brand-blue;
        @include transition( $base-transition );
      }
    }
  }

  #hamburger {
    .hamburger-inner {
      background-color: $hamburger-color-active;
      &:after, &:before {
        background-color: $hamburger-color-active;
      }
    }
  }
}

.bg-color-dark,
[data-bgColor="dark-split"] {
  #site-header {
    .logo {
      #ofo-logo {
        @media (min-width: #{$tablet-bp}) {
          fill: $brand-blue;
        }
        @include transition( $base-transition );
      }
      #logo-text-bg {
        fill: $white;
        @include transition( $base-transition );
      }
    }
  }

  #hamburger {
    .hamburger-inner {
      @include transition( $base-transition );

      @media (min-width: 1023px) {
        background-color: white;
      }
      &:after, &:before {
        @include transition( $base-transition );
        @media (min-width: 1023px) {
          background-color: white;
        }
      }
    }
  }
}


#nav-main {
  a {
    @extend .content-h1;
    opacity: 1;
    transform: translateZ(0);
    transition: opacity .7s cubic-bezier(.23,1,.32,1);

    @media (max-width: #{$tablet-bp}) {
      font-size: 36px;
    }

    @media (max-width: #{$mobile-bp}) {
      font-size: 32px;
    }

    &:hover {
      opacity: 0.5;
      color: white;
      text-decoration: none;
    }
  }

  .current-menu-item a {
    opacity: 0.5;
  }
}
