@import '~marx-css/scss/marx';
@import '~hamburgers/_sass/hamburgers/hamburgers';

@import 'partials/mixins';
@import 'partials/helpers';
@import 'partials/forms';
@import 'partials/pagination';
@import 'partials/social-links-block';

@import 'base';

@import 'sections/site-header';
@import 'sections/footer';

@import 'variables';

@import 'singular';
@import 'blog';
@import 'front-page';
@import 'profiles';
@import 'contact';

body {
  overflow-x: hidden;
}

// Marx extensions
.button {
  @extend button;
  -webkit-appearance: none; // Fix for weird implementation see: https://developer.mozilla.org/en-US/docs/Web/CSS/appearance
  -moz-appearance: none;
}

main {
  max-width: 100%;
  padding-bottom: 0;
  overflow-x: hidden;

  article {
    margin-bottom: 0;
  }
}

.home main {
  //background-color: $brand-blue;
  background-color: #0C2546;
}



h1 {
  @extend .base-h1;
}

h2 {
  @extend .base-h2;
}

h4 {
  @extend .base-h2;
}

p, ul, ol {
  @extend .base-p;
}

.font-white {
  color: white;
}

.brand-bg-rgba {
  background-color: $brand-blue-overlay;
}

.brand-bg-rgba-overlay {
  &:before {
    @extend .brand-bg-rgba;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  &:after {
    content: '';
    background-color: $brand-blue !important;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

.white-bg {
  background-color: white;
}

.big-font {
  font-size: 100px;
  line-height: 100px;

  @media (max-width: #{$mobile-bp}) {
    font-size: 72px;
    line-height: 72px;
  }

}

.logo {
  max-width: 100px;
  margin-top: 0;
  margin-bottom: 0;

  @media (min-width: #{$mobile-bp}) {
    max-width: 130px;
  }
}

.js .background-image-wrapper {
  //opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;

  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.js .home .background-image-wrapper,
.js .singular-active .background-image-wrapper {
  @include transition($base-slide-transition);
  transform: scale3d(0.8, 0.8, 0.8);
}


.js .home .active .background-image-wrapper,
.js .singular-active .background-image-wrapper {
  opacity: 1;
  transform: scale3d(1, 1, 1);
  @include transition( $base-slide-transition );
}

.fullpage-bg-video {
  z-index: -1;
  opacity: 0;
  @include transition( $base-slide-transition );
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.active .fullpage-bg-video {
  opacity: 1;
  zoom: 1;
  @include transition( $base-slide-transition );
}

.video-modal {
  visibility: hidden;
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(25,63,110, 0.85);

  &.active {
    visibility: visible;
  }

  #main-video {
    position: absolute;
    height: auto;
    width: 70vw;
    top: 50%;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
  }

}

.block .st0 {
  fill: $brand-blue !important;
}

.article-types {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;


  .article-type-block {
    background-size: cover;
    width: calc(25% - 1rem);
    height: 350px;
    position: relative;
    margin-bottom: 2rem;

    @media (max-width: #{$mobile-bp}) {
      display: none;
    }


    &:hover {
      &:before {
        background-color: rgba(25, 63, 110, 1);
        @include transition( $base-transition );
      }
    }

    &:before {
      @include transition( $base-transition );
      content:'';
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: block;
      z-index:0;
      background-color: rgba(25, 63, 110, 0.65);

    }

    a {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h4 {
        color: white;
        text-align: center;
      }

    }


  }
}

.our-thinking-page .article-types .article-type-block {

  @media (max-width: #{$mobile-bp}) {
    display: block;
    width: calc(50% - 0.5rem);
  }

  @media (max-width: #{$sm-bp}) {
    width: 100%;
  }

}

.article-listing-page {

  #article-content .main-content {
    max-width: 100%;
    width: 100%;
  }

  background-color: rgba(200,210,222,1);

  .article-block {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: white;
    display: flex;
    flex-wrap: wrap;


    .image-link-wrapper, img, .content {
      width: 300px;

      @media (max-width: #{$sm-bp}) {
        width: 100%;
        max-width: 100%;
      }
    }

    .content {
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
      @media (max-width: #{$sm-bp}) {
        padding-left: 0;
      }
    }

    h4 {
      @extend .base-h1;
      margin-bottom: 0.25rem;
    }

    .read-more {
      background-color: $color-1;
      color: white;
      padding: 0.5rem;
      font-weight: normal;
      align-self: flex-end;
      justify-self: flex-end;
    }

    .date {
      font-size: 18px;
      color: #999;
      margin-top: 0;
    }

    .pdf-thumb {
      border: 1px solid #c3c3c3;
    }

  }
}

.at-icon {
  fill: white !important;
}


