@import '../variables';


.footer {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  position: relative;
  z-index: 100;
  text-align: left;


  .content-container {
    @extend .base-content-container;

    .content {
      padding-top: 16px;

      @media (min-width: #{$mobile-bp}) {
        padding-top: 32px;
      }
    }
  }

  .logo {
    margin-bottom: 4rem;
  }

  a {
    color: white;
  }

  .content-left a {
    font-weight: bold;
    color: #889cbb;
    &:hover {
      color: white;
    }
  }

  .made-by {
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 0 1rem;
    left: 0;
    right: 0;
    font-size: 14px;
    color: #666;

    a {
      color: inherit;

      &:hover {
        color: white;
      }
    }
  }

}

.footer, #section-7 {
  .content-two-columns {
    align-items: inherit;
  }
}
