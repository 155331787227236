@import 'variables';

.profile-page #hero {
  .background-image-wrapper {
    top: 0;

    &:after {
      background: transparent;
    }
  }

  .background-image {
    margin: 0 auto;
    height: initial;
    min-height: 100vh;
  }

  .brand-bg-rgba-overlay:before {
    background-color: transparent;
  }

  .no-hero-image {
    img {
      opacity: 0;
    }

    &:before {
      background-color: $brand-blue !important;
    }
  }
}

.profile.block {
  .profile-title {
    margin-top:10px;
    font-weight: normal;
    font-size: 18px;
  }
}

