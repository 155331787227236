@import '../variables';
@import 'mixins';

$color-1: #51A0D1;

.arrow-circle-button {
  cursor: pointer;
  position: relative;
  width: 220px;
  height: 60px;
  display: flex;
  align-items: center;

  @include transition( $base-transition );



  .circle {
    height: 60px;
    width: 60px;
    border-radius: 30px;

    position: absolute;
    top: 0;
    right: -20px;
    background-color: $color-1;
    @include transition( $base-transition );
  }

  &.hover .arrow {
    width: 95%;
    @include transition( $base-transition );
  }


  .arrow {
    width: 100%;
    height: 2px;
    display: block;
    background-color: $color-1;
    position: relative;
    @include transition( $base-transition );

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 15px solid white;
      position: absolute;
      right: 2px;
      top: -7px;
      z-index: 100;
      @include transition( $base-transition );
    }

  }

  .arrow-text {
    position: absolute;
    color: white;
    text-transform: uppercase;
    top: 0;
  }

  &:hover {

    @include transition( $base-transition );

    .arrow {
      background-color: white;
      @include transition( $base-transition );

      &:before {
        border-left: 15px solid $color-1;
        @include transition( $base-transition );
      }
    }

    .circle {
      @include transition( $base-transition );
      background-color: white;
    }

  }
}