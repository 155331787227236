@import 'variables';
@import 'partials/mixins';
@import 'sections/article-hero';

.base-content-width {
  max-width: $site-max-width;
  margin: 0 auto;
}

.base-content-layout {
  @extend .base-content-width;
  margin: 100vh auto auto;
  display: flex;

  .main-content {
    max-width: 690px;
    padding: 3rem 0;
  }

  h1 {
    color: $color-1;
    font-size: $font-size-4;

    @media (min-width: #{$mobile-bp}) {
      font-size: $font-size-2;
    }
  }

  h2 {
    @extend .content-h2;
  }

  ul, ol {
    @extend .content-list;
  }
}

main > article {
  a {
    font-weight: bold;
  }
}

.singular-page {

  .hero {
    @extend .article-hero;
  }

  #article-content {
    @extend .base-content-layout;
  }

  .article-content-bottom, .article-footer {
    margin: 0 -1rem;
    background-color: white;

    .inner {
      @extend .base-content-width;
      padding: 4rem 16px;
    }
  }
  .article-content-bottom {
    background-color: $light-blue;

    h4 {
      @extend .content-h2;
    }

    h2, p{
      color: white;
    }

  }

  .article-footer {
    .content-left, .content-right {
      flex: 1;

      @media (max-width: 650px) {
        flex: auto;
      }
    }

    .content-left {
      align-self: center;
      justify-content: center;
    }

  }

  .aside {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;

    @media (max-width: #{$tablet-bp}) {
      display: none;
    }

    .active-quote {
      padding: 3rem;
      display: flex;
      align-items: center;
      flex: 1;
      position: fixed;
      top: 0;
      height: 100vh;
      z-index: -1;

      p {
        font-family: $sans-serif-font;
        text-align: center;
        color: $brand-blue;
        font-weight: bold;
        font-size: 36px;
        line-height: 1.25;
      }
    }

    #ofo-sb-circle {
      position: absolute;
      z-index: -2;
      width: 100vw;
      left: 0;
      top: 100px;
      @include transition( opacity .3s ease-in-out );

      &.active {
        opacity: 1;
        @include transition( opacity .3s ease-in-out );
      }
    }



    .sb-quotes {
      display: none;
    }
  }
}

.block-grid {
  display: flex;
  justify-content: space-between;
  margin: 4rem 0;
  flex-wrap: wrap;

  @media (max-width: $mobile-bp) { // Profiles break into single column
    justify-content: center;
  }

    .block {
    max-width: 370px;
    margin-bottom: 2rem;
    padding: 1rem;
    background-color: white;

    svg {
      width: 50px;
      fill: $brand-blue;
    }

    h4 {
      @extend .base-h2;
      margin-bottom: 1rem;
      text-transform: inherit;
    }

    p {
      color: $base-font-color;
      font-size: 16px;
    }
  }

  .profile.block, .blog-post.block {
    overflow: hidden;
    background-color: transparent;

    .profile-content {
      padding: 1rem;
    }

    .profile-name {
      color: $brand-blue;
      font-weight: bold;

      @media (max-width: #{$mobile-bp}) {
        margin-top: 10px;
      }
    }

    .profile-title {
      font-weight: bold;
    }

    h2 {
      margin: 0 0 0 0;
    }

    img {
      object-fit: cover;
      @include transition( all .6s ease-in-out );
      transform: scale(1);
      vertical-align: bottom;
    }

    .img-wrapper {
      overflow: hidden;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 1;
        opacity: 0;
        @include transition( all .6s ease-in-out );
      }
    }

    a:hover {
      text-decoration: none;

      .img-wrapper:before {
        @include transition( all .6s ease-in-out );
        opacity: 0.4;
      }

      img {
        transform: scale(1.05);
        @include transition( all .6s ease-in-out );
      }
    }
  }
}

.our-team-page #article-content .main-content{
  max-width: 100%;
}

.our-team-page .active-quote {
  display: none !important;
}

.our-team-page .content-two-columns .content-right {
  max-width: 100%;
}

.single-articles  {
  main {
    overflow: hidden;
  }

  .main-content {
    width: 100%;
    min-height: 1000px;
  }

  .aside {
    //display: none;
  }
}
