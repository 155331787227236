@import 'variables';
@import 'partials/_mixins.scss';

a {
  color: $color-1;
  @include transition( $base-transition );
  &:hover {
    text-decoration: none;
    @include transition( $base-transition );
  }
}

.link-color-inverse {
  color: $light-blue;
  @include transition( $base-transition );

  &:hover {
    color: $brand-blue !important;
    @include transition( $base-transition );
  }
}

.white-hov:hover {
  color: white !important;
}

.base-h1 {
  color: $brand-blue;
  font-family: $serif-font;
  font-size: 32px;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (min-width: #{$mobile-bp}) {
    font-size: 48px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.base-h2 {
  color: $color-1;
  font-size: 24px;
  font-family: $sans-serif;
  font-weight: bold;
  margin-top: 32px;

  @media (max-width: #{$mobile-bp}) {
    font-size: 18px;
  }
}

.base-h4 {
  color: $color-1;
  font-size: $font-size-6;
  font-family: $sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

.base-p {
  color: $base-font-color;
  font-weight: 200;
  font-size: $font-size-base;
}

.content-h1 {
  @extend .base-h1;

  @media (min-width: #{$mobile-bp}) {
    font-size: 48px;
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

.content-h2 {
  @extend .base-h2;
  text-transform: uppercase;
}

.content-list {
  margin-top: 0;
  margin-bottom: 24px;
}

.base-content-container {
  position: relative;
  min-height: 100vh;
  width: 100vw;

  .content {
    //max-width: $site-max-width;
    margin: 0 auto;
    //padding-left: 1rem;
    //padding-right: 1rem;
    padding: 16px;
    padding-bottom: 130px;

    @media (min-width: #{$mobile-bp}) {
      padding: 32px 8%;
    }
  }

}

.close-button {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 45px;
  height: 45px;
  opacity: 0.3;
}
.close-button:hover {
  opacity: 1;
}
.close-button:before, .close-button:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 45px;
  width: 4px;
  background-color: #ebebeb;
}
.close-button:before {
  transform: rotate(45deg);
}
.close-button:after {
  transform: rotate(-45deg);
}
