@import '../variables';
@import 'mixins';

form {

  p {
    margin-bottom: 0;
  }
  label {
    line-height: 1.25rem;
  }

  div.wpcf7-validation-errors {
    background-color: #f7e700;
    color: white;
  }

  .wpcf7-submit {
    background-color: $light-blue;
    cursor: pointer;
  }

  .wpcf7-list-item {
    margin-left: 0 !important;
    font-size: 16px;

    label {
      display: flex;
      align-items: center;
    }

    input[type=checkbox] {
      margin-right: 10px;
    }
  }
}

input:focus:invalid {
  color: rgba(0, 0, 0, 0.8) !important;
  border-color: inherit !important;
}
