@import 'variables';

.page-blog {

  .block-grid {
    margin-top: 0;

    .block {
      width: 30%;

      @media (max-width: 700px) {
        width: 50%;
      }

      @media (max-width: 700px) {
        width: 50%;
      }

      @media (max-width: 450px) {
        width: 100%;
      }

      h4 {
        margin-top: 1rem;
      }

      h6 {
        font-weight: 200;
        color: $base-font-color;
      }
    }
  }

  #article-content .main-content {
    max-width: $site-max-width;
  }
}
